import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { getSrc } from "gatsby-plugin-image"
import styled from "styled-components"
import { fontBody, fontDecor, colors } from "../utils/styles"
import { AboutHeader } from "../utils/Header"
import { BannerCenter } from "../utils"

const HeroText = styled.p`
  color: ${colors.mainWhite};
  font-size: 1.25rem;
  font-weight: medium;
  margin: 0 auto;
`

const ImgWrapper = styled.div`
  height: fit-content;
  margin: auto;
  width: fit-content;

  &.hideOnMobile {
    display: none;
  }

  &.fullWidthContentImg {
    max-width: 1260px;
    padding: 1rem;

    img {
      width: 100%;
    }
  }

  &.clippedImg {
    img {
      clip-path: polygon(0% 0%, 90% 0%, 100% 10%, 100% 100%, 10% 100%, 0% 90%);
    }

    img {
      max-width: 100%;
      object-fit: cover;
    }
  }

  @media (min-width: 768px) {
    &.hideOnMobile {
      display: block;
    }
  }
`

const Section1Wrapper = styled.section`
  display: grid;
  gap: 1rem;
  grid-template: var(--s1w-grid-template, auto auto / 1fr);
  margin: 1rem auto 0;
  max-width: 1260px;
  padding: 0 1rem;
  place-content: center;

  @media (min-width: 768px) {
    --s1w-grid-template: auto / repeat(2, 1fr);
  }
`

const Section1ImgContainer = styled.div`
  background: ${colors.mainWhite};
  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 1rem;

  @media (min-width: 768px) {
    --hero-grid-area: 1 / 2;
  }
`

const Section1Copy = styled.article`
  background: ${colors.primaryColor};
  color: ${colors.mainWhite};
  display: grid;
  outline: 2px solid ${colors.secondaryColor};
  outline-offset: -1rem;
  padding: 10%;
  place-content: center;

  p {
    font-size: 1.2rem;
  }

  @media (min-width: 768px) {
    --hero-grid-area: 1 / 1;
  }
`

const EventsSection = styled.section`
  display: grid;
  grid-template: var(--es-grid-template, 1fr auto auto / 1fr);
  gap: 1rem;
  margin: 0 auto 1rem;
  max-width: 1260px;
  padding: 0 1rem;
  place-content: center;

  @media (min-width: 768px) {
    --es-grid-template: 1fr auto / 1fr 1fr;

    & > *:first-child {
      grid-area: 1 / 2 span;
    }
  }

  @media (min-width: 992px) {
    --es-grid-template: 1fr auto / 1fr 40%;

    & > *:first-child {
      grid-area: 1 / 1;
    }

    & > *:last-child {
      grid-area: 2 / 2 span;
    }
  }
`

const EventsArticleBackgroundStyler = styled.div`
  background: ${colors.mainBlack};
  display: grid;
  min-height: max(var(--eabs-min-h, 25rem), min(50vh, 400px));
  outline: 2px solid ${colors.secondaryColor};
  outline-offset: -1rem;
  place-content: center;

  @media (min-width: 992px) {
    --eabs-min-h: auto;
  }
`

const EventsArticle = styled.article`
  color: ${colors.mainWhite};
  font: ${fontBody({ lineHeight: `1.5`, size: `1.2rem` })};
  height: fit-content;
  margin: auto;
  padding: var(--ea-padding, 4rem 2.5rem);

  header h2 {
    color: ${colors.secondaryColor};
    font: ${fontDecor({
      lineHeight: `1.5`,
      size: `clamp(1.5rem, 10vw, 3rem)`,
    })};
  }

  p {
    margin-bottom: 0;
    margin-top: var(--ea-p-mt, 1rem);
  }

  @media (min-width: 768px) {
    --ea-p-mt: 2rem;
    --ea-padding: 4rem;
  }
`

const PatioPage = ({
  data: {
    menu1,
    order1,
    landscapeHero,
    portraitHero,
    contentImg1,
    eventsImg1,
    eventsImg2,
  },
}) => {
  const menu1Img = getSrc(menu1)
  const order1Img = getSrc(order1)
  const contentImg = getSrc(contentImg1)
  const eventsImg = getSrc(eventsImg1)
  const afterEventsImg = getSrc(eventsImg2)

  const heroImages = {
    lgImg: getSrc(landscapeHero),
    smImg: getSrc(portraitHero),
  }

  return (
    <Layout>
      <Seo
        img={heroImages.lgImg}
        keywords={[`Outside dining in Kenilworth, Asheville, NC`]}
        title="Patio"
      />
      <AboutHeader {...heroImages}>
        <BannerCenter
          title="Outdoor Dining"
          titleProps={{
            style: {
              fontSize: `clamp(1.5rem, 15vw, 3.8rem)`,
              textAlign: `left`,
            },
          }}
          subtitle=""
          bannerProps={{ pFontSize: `1.25rem` }}
        >
          <HeroText>
            Azalea Bar & Kitchen is home to the BEST patio between Biltmore and
            Downtown. Fully appointed with all you need to enjoy time with
            friends and neighbors, our outdoor space is comfortable and
            relaxing.
          </HeroText>
        </BannerCenter>
      </AboutHeader>
      <Section1Wrapper>
        <Section1ImgContainer>
          <ImgWrapper className="clippedImg">
            <img
              alt="Two guests in rocking chairs look at the drinks menu"
              src={menu1Img}
            />
          </ImgWrapper>
          <ImgWrapper className="clippedImg hideOnMobile">
            <img
              alt="Three guests tuck into their dinner"
              loading="lazy"
              src={order1Img}
            />
          </ImgWrapper>
        </Section1ImgContainer>
        <Section1Copy className="patioAtfCopy">
          <p>
            Gather al fresco for locally brewed beer and elevated,
            southern-inspired meals prepared by a chef who knows and loves this
            city.
          </p>
          <p>
            With tables for dining, couches for lounging, and rocking chairs for
            maximizing comfort, it’s a great space for a first date under the
            romantic glow of the string lights, a family dinner by the warmth of
            the fire pits, or enjoying Saturday or Sunday morning brunch under a
            sunshade. It’s like a rooftop bar for people who are afraid of
            heights.
          </p>
        </Section1Copy>
      </Section1Wrapper>
      <ImgWrapper className="fullWidthContentImg">
        <img
          alt="A group of friends relax with drinks and appetizers on the patio before sunset."
          loading="lazy"
          src={contentImg}
        />
      </ImgWrapper>
      <EventsSection>
        <EventsArticleBackgroundStyler>
          <EventsArticle>
            <header>
              <h2>Private Events</h2>
            </header>
            <p>
              With room to accommodate up to 100 people, our patio and the
              adjacent grounds are inviting spaces suitable for any ceremony,
              celebration, meeting, reception, shower, or party. Guests enjoy
              ample space for socializing and mingling, and hosts can rest
              assured their event is in good hands. In addition to our outdoor
              space, our first-floor dining and meeting rooms can facilitate any
              corporate or social event.
            </p>
          </EventsArticle>
        </EventsArticleBackgroundStyler>
        <ImgWrapper className="clippedImg">
          <img
            alt="A server carrying three plates of food to an outdoor table."
            loading="lazy"
            src={eventsImg}
          />
        </ImgWrapper>
        <ImgWrapper className="clippedImg">
          <img
            alt="Two people holding drinks and talking on the patio during sunset."
            loading="lazy"
            src={afterEventsImg}
          />
        </ImgWrapper>
      </EventsSection>
    </Layout>
  )
}

export default PatioPage

export const query = graphql`
  query {
    menu1: file(relativePath: { eq: "fall-2024/AzaleaSept2024_279.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          webpOptions: { quality: 75 }
          formats: [AVIF, WEBP]
          placeholder: BLURRED
          transformOptions: { cropFocus: ENTROPY, fit: COVER }
          width: 630
        )
      }
    }
    order1: file(relativePath: { eq: "fall-2024/AzaleaSept2024_243.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          webpOptions: { quality: 75 }
          formats: [WEBP]
          placeholder: BLURRED
          transformOptions: { cropFocus: ENTROPY, fit: COVER }
          width: 630
        )
      }
    }
    landscapeHero: file(
      relativePath: { eq: "fall-2024/AzaleaSept2024_222.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          webpOptions: { quality: 75 }
          formats: [WEBP]
          layout: FULL_WIDTH
          placeholder: BLURRED
        )
      }
    }
    portraitHero: file(
      relativePath: { eq: "fall-2024/abk-patio-evening-ambience-p-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          webpOptions: { quality: 75 }
          formats: [WEBP]
          layout: FULL_WIDTH
          placeholder: BLURRED
        )
      }
    }
    contentImg1: file(relativePath: { eq: "fall-2024/AzaleaSept2024_171.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          webpOptions: { quality: 75 }
          formats: [WEBP]
          # height: 630
          placeholder: BLURRED
          width: 1260
        )
      }
    }
    eventsImg1: file(relativePath: { eq: "fall-2024/AzaleaSept2024_246.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          webpOptions: { quality: 75 }
          formats: [WEBP]
          placeholder: BLURRED
          width: 992
        )
      }
    }
    eventsImg2: file(relativePath: { eq: "fall-2024/AzaleaSept2024_324.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          webpOptions: { quality: 75 }
          formats: [WEBP]
          placeholder: BLURRED
          width: 1260
        )
      }
    }
  }
`
